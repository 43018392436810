
import { defineComponent } from 'vue'
import { FormualarioNotificacoes } from './IFormulario'

export default defineComponent({
  props: {
    notificacoes: Array,
  },

  methods: {
    temNotificacoes(): boolean {
      return this.notificacoes?.length != 0
    },
  },

  computed: {
    foiSucesso(notificacao: FormualarioNotificacoes): boolean {
      return notificacao.sucesso
    },
  },
})
