
import Notificacoes from './Notificacoes.vue'
import { ResultadoDaRequisicao } from '@/util/IRequisicoes'

import {
  IFormularioElementoInput,
  IFormularioDadosInputs,
  FormualarioNotificacoes,
} from './IFormulario'

import { defineComponent } from 'vue'
import { Formulario } from './Formulario'
import { Util } from '@/util'

export default defineComponent({
  data() {
    return {
      notificacoes: [] as FormualarioNotificacoes[],
    }
  },

  components: {
    Notificacoes,
  },

  props: {
    formulario: Object,
  },

  methods: {
    async enviarFormulario(e: Event) {
      e.preventDefault()

      this.notificacoes = []
      let dados: IFormularioDadosInputs = { ...Formulario.obterDadosInputs() }

      if (Util.emailValido(dados.email as string)) {
        this.ativarBotaoFormulario(false)

        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('login')

        dados.captcha = token
        dados.dominio = Util.obterUrlPagina()
        dados.horario = new Date()
        dados.ip = await Util.obterIpUsuario()

        try {
          this.notificarResultadoEnvio(
            await this.enviarFormularioServidor(dados)
          )
        } catch (err) {
          this.notificacoes.push(
            Formulario.notificacao(err.response.data.alertas.shift(), false)
          )
          this.ativarBotaoFormulario(true)
        }
        //...
      } else {
        this.notificacoes.push(
          Formulario.notificacao('Preencha corretamente o formulário!', false)
        )
        this.ativarBotaoFormulario(true)
      }
    },

    notificarResultadoEnvio(resultado: ResultadoDaRequisicao) {
      if (!resultado.alertas) {
        this.notificacoes.push(
          Formulario.notificacao('E-mail recebido com sucesso!', true)
        )
        this.desativarFormulario()
      } else {
        this.notificacoes.push(
          Formulario.notificacao(resultado.alertas?.shift() as string, false)
        )
        this.ativarBotaoFormulario(true)
      }
    },

    async enviarFormularioServidor(
      informacoes: IFormularioDadosInputs
    ): Promise<ResultadoDaRequisicao> {
      try {
        const { data } = await this.axios.post(
          this.formulario?.urlBotao as string,
          informacoes
        )
        return data as ResultadoDaRequisicao
      } catch (erro) {
        throw erro
      }
    },

    // Utilitarios do Formulário
    ativarBotaoFormulario(status = true): void {
      ;(<HTMLInputElement>(
        document.getElementById('formulario__enviar')
      )).disabled = !status
    },

    desativarFormulario(): void {
      let formulario = document.getElementById('formulario__form')
      let areaFormulario = document.getElementById('formulario__bloco')
      areaFormulario?.removeChild(formulario as Node)
    },

    montarHtmlInput(input: IFormularioElementoInput) {
      return Formulario.gerarHtmlInput(input)
    },

    definirColunasInput(input: IFormularioElementoInput) {
      return input.col ? input.col : 'col-md-12'
    },
  },
})
