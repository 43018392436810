<template>
  <router-view />
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import './assets/css/root.css'
import './assets/css/fonts.css'
import './assets/css/html.css'
import './assets/css/style.css'
import './assets/css/geral.css'

export default {
  name: 'App',
  components: {},
}
</script>
